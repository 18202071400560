<template>
	<div>
		<h1>Error</h1>
		<div>{{ $route.query.error }}</div>
		<div>{{ $route.query.error_description }}</div>
	</div>
</template>


<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>